@font-face {
  font-family: Roobert;
  font-weight: bold;
  src: url("../assets/fonts/roobert-bold.otf") format("opentype");
}

@font-face {
  font-family: Roobert;
  src: url("../assets/fonts/roobert-medium.otf") format("opentype");
}

$buttercup-yellow: #EFB017;
$baby-blue: #8CAADC;
$deep-pink: #FCB1CA;
$fun-green: #005E32;
$navy-blue: #010964;
$thunderbird-red: #C51A14;
$silver-brown: #CEC3BC;

.black--bg { background-color: black; color: white; svg { fill: white !important; stroke: white !important; } }
.white--bg { background-color: white; color: black; svg { fill: black !important; stroke: black; } }
.butterfly-yellow--bg { background-color: $buttercup-yellow; color: black; svg { fill: black; stroke: black; } }
.baby-blue--bg { background-color: $baby-blue; color: black; svg { fill: black; stroke: black; } }
.deep-pink--bg { background-color: $deep-pink; color: black; svg { fill: black; stroke: black; } }
.fun-green--bg { background-color: $fun-green; color: white; svg { fill: white; stroke: white; } }
.navy-blue--bg { background-color: $navy-blue; color: white; svg { fill: white; stroke: white; } }
.thunderbird-red--bg { background-color: $thunderbird-red; color: white; svg { fill: white; stroke: white; } }
.silver-brown--bg { background-color: $silver-brown; color: black; svg { fill: black; stroke: black; } }

* {
  font-family: 'Roobert', sans-serif;
}

.wrapper {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
}

.text-wrapper {
  max-width: 950px;
  margin: 0 auto;
}

h1, h2, h3, h4, p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, p {
  font-weight: 400;
}

h1 {
  font-size: 200px;
  line-height: 180px;

  &.large {
    font-size: 400px;
    line-height: 400px;
  }
}

h2 {
  font-size: 80px;
  line-height: 80px;
}

h3 {
  font-size: 60px;
  line-height: 70px;
}

h4 {
  font-size: 40px;
  line-height: 48px;
}

p {
  font-size: 24px;
  line-height: 40px;

  &.md {
    font-size: 20px;
    line-height: 32px;
  }

  &.sm {
    font-size: 16px;
    line-height: 25px;
  }
}

small {
  font-size: 14px;
  line-height: 17px;
}

// MOBILE

@media (max-width: 768px) {
  h1 {
    font-size: 60px;
    line-height: 60px;

    &.large {
      font-size: 120px;
      line-height: 120px;
    }
  }

  h2 {
    font-size: 60px;
    line-height: 60px;
  }

  h3 {
    font-size: 40px;
    line-height: 45px;
  }

  h4 {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 60px;
    line-height: 60px;

    &.large {
      font-size: 80px;
      line-height: 80px;
    }
  }

  h2 {
    font-size: 45px;
    line-height: 45px;
  }

  h3 {
    font-size: 40px;
    line-height: 45px;
  }

  h4 {
    font-size: 28px;
    line-height: 36px;
  }
}

.left-text-block {
  width: 100%;
  max-width: 750px;
  margin: 0 0 0 auto;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}